import { apiUrls } from '../config';
import fetch from 'isomorphic-unfetch';
import { getCookie, removeCookie } from './session';

function makeHeaders(token) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getCookie(getTokenName())}`,
  };

  if (token) {
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  }

  if (!getCookie(getTokenName())) {
    window.location.href = '/signin';
    removeCookie();

    return {};
  }

  return headers;
}

export const parseOptions = (options) => {
  let params = '';
  if (options)
    Object.keys(options).map((option, index) => {
      let value = Object.values(options)[index];
      if (value != null || value != undefined) {
        params += `${index !== 0 ? '&' : ''}${option}=${value}`;
      }
    });
  return params;
};

const parseResponse = async (res) => {
  let json;

  try {
    json = await res.json();
  } catch (error) {}

  if (res.ok) {
    return json;
  }

  if (res.status === 401 || json?.authErrors) {
    window.location.href = '/signin';
    removeCookie();
  }

  throw json;
};

const parseErrors = async (err) => {
  if (err) return err;
};

const getTokenName = () => {
  return `id_token`;
};

class Api {
  login = async (userInfo) => {
    return await fetch(apiUrls.login, {
      method: 'POST',
      body: JSON.stringify(userInfo),
    }).then(parseResponse);
  };

  logout = async () => {
    return await fetch(apiUrls.logout, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  verificarToken = async (token) => {
    return await fetch(apiUrls.verificar + '/' + token, {
      method: 'GET',
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  verifyResetPasswordToken = async (token) => {
    return await fetch(`${apiUrls.verifyResetPasswordToken}/${token}`, {
      method: 'GET',
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  createResetPassword = async (email) => {
    return await fetch(apiUrls.create, {
      method: 'POST',
      body: JSON.stringify(email),
    }).then(parseResponse);
  };

  resetPassword = async (userInfo) => {
    return await fetch(apiUrls.resetPassword, {
      method: 'POST',
      body: JSON.stringify(userInfo),
    }).then(parseResponse);
  };

  sendRecommendation = async ({
    nome,
    email,
    telefone,
    codigo_empresa,
    codigo_usuario,
  }) => {
    return await fetch(apiUrls.recommendation, {
      method: 'post',
      body: JSON.stringify({
        nome,
        email,
        telefone,
        codigo_empresa,
        codigo_usuario,
      }),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchCompanies = async (payload) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.userCompanies}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  changeCompany = async (companyId) => {
    return await fetch(`${apiUrls.changeCompany}/${companyId}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  getConfigOnboarding = async () => {
    return await fetch(apiUrls.config, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  //REQUESTS PARA ALUNO
  fetchListAlunos = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.getAlunos}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  fetchCPF = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.getPessoa}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchAluno = async (alunoId) => {
    return await fetch(`${apiUrls.getPessoa}/${alunoId}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getAlunoIntegracao = async (alunoId) => {
    return await fetch(`${apiUrls.getAlunoIntegracao}/${alunoId}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  insertAlunos = async (payload) => {
    return await fetch(apiUrls.insertPessoa, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  importStudent = async (payload) => {
    return await fetch(apiUrls.importStudent, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  patchAlunos = async (payload) => {
    return await fetch(apiUrls.patchPessoa + '/' + payload.codigo, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  patchAlunosObservacao = async (payload) => {
    return await fetch(apiUrls.patchObservacao + '/' + payload.codigo, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  updateAluno = async (aluno) => {
    return await fetch(`${apiUrls.updatePessoa}/${aluno.codigo}`, {
      method: 'PUT',
      body: JSON.stringify(aluno),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  fetchBairros = async () => {
    return await fetch(apiUrls.bairro, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  deleteAluno = async (id) => {
    return await fetch(`${apiUrls.deletePessoa}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
      body: JSON.stringify({
        cliente: 1,
      }),
    }).then(parseResponse);
  };

  inactiveAluno = async (id, status) => {
    return await fetch(`${apiUrls.deletePessoa}/${id}/${status}`, {
      method: 'DELETE',
      headers: makeHeaders(),
      body: JSON.stringify({
        cliente: 1,
      }),
    }).then(parseResponse);
  };

  insertFoto = async (payload, id) => {
    return await fetch(`${apiUrls.insertFoto}/${id}`, {
      method: 'POST',
      body: JSON.stringify({ foto: payload, cliente: 1 }),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getCnhs = async (id) => {
    return await fetch(`${apiUrls.getCNHS}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  insertCNH = async (payload) => {
    return await fetch(apiUrls.insertCNH, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  updateCNH = async (payload, idCNH) => {
    return await fetch(`${apiUrls.updateCNH}/${idCNH}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  deleteCNH = async (id) => {
    return await fetch(`${apiUrls.deleteCNH}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  getFilesAlunos = async (id) => {
    return await fetch(`${apiUrls.getFilesAluno}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  getFinanceiroAluno = async (id) => {
    return await fetch(`${apiUrls.getFinanceiroAluno}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  getInfoInstallments = async (id) => {
    return await fetch(`${apiUrls.infoInstallments}`, {
      method: 'POST',
      body: JSON.stringify(id),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  payInstallments = async (payload) => {
    return await fetch(`${apiUrls.payInstallments}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  devolverParcelas = async (payload) => {
    return await fetch(`${apiUrls.returnedInstallments}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  dividerInstallments = async (payload) => {
    return await fetch(`${apiUrls.dividerInstallments}/${payload.codigo}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  reopenInstallments = async (payload, quantidade_parcelas) => {
    return await fetch(
      `${apiUrls.reopenInstallments}/${payload}/${quantidade_parcelas}`,
      {
        method: 'PUT',
        headers: makeHeaders(),
      }
    ).then(parseResponse);
  };

  reopenInstallmentsStatement = async (payload, quantidade_parcelas) => {
    return await fetch(
      `${apiUrls.reopenInstallmentsStatement}/${payload}/${quantidade_parcelas}`,
      {
        method: 'PUT',
        headers: makeHeaders(),
      }
    ).then(parseResponse);
  };

  excluirParcela = async (payload) => {
    return await fetch(`${apiUrls.deleteInstallments}/${payload.codigo}`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  encerrarParcelas = async (payload) => {
    return await fetch(`${apiUrls.closeInstallments}/${payload}`, {
      method: 'POST',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  completeProcess = async (payload, id) => {
    return await fetch(`${apiUrls.completeProcess}/${id}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  reopenProcess = async (id) => {
    return await fetch(`${apiUrls.reabrirProcess}/${id}`, {
      method: 'PUT',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  removeProcess = async (id, forcarExcluir = false) => {
    return await fetch(
      `${apiUrls.removeProcess}/${id}?forcar-excluir=${forcarExcluir}`,
      {
        method: 'DELETE',
        headers: makeHeaders(),
      }
    )
      .then(parseResponse)
      .catch(parseErrors);
  };

  //REQUESTS PARA VENDAS
  insertVenda = async (payload) => {
    return await fetch(apiUrls.insertVenda, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  insertSaleService = async (payload) => {
    return await fetch(apiUrls.insertSaleService, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  aprovarVenda = async (payload) => {
    return await fetch(apiUrls.aprovar, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  getVenda = async (id) => {
    return await fetch(`${apiUrls.getVendaPersonalizada}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchListVendas = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.getVenda}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  fetchListVendaRapida = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.getVendaRapida}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  insertVendaRapida = async (payload) => {
    return await fetch(apiUrls.insertVendaRapida, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getFormaPagamento = async (id) => {
    return await fetch(`${apiUrls.getFormaPagamento}/${id}?select=true`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getParcelas = async (codigoProcesso, codigoFormaPagamento, token) => {
    return await fetch(
      `${apiUrls.getParcelas}/${codigoProcesso}/${codigoFormaPagamento}`,
      {
        method: 'GET',
        headers: makeHeaders(),
      }
    )
      .then(parseResponse)
      .catch(parseErrors);
  };

  getAumentoDesconto = async (codigoProcesso, codigoFormaPagamento, qtdParcelas) => {
    return await fetch(
      `${apiUrls.getAumentoDesconto}/${codigoProcesso}/${codigoFormaPagamento}/${qtdParcelas}`,
      {
        method: 'GET',
        headers: makeHeaders(),
      }
    )
      .then(parseResponse)
      .catch(parseErrors);
  };

  getNomeFunc = async () => {
    const options = {
      situacao: 1,
      funcionario: 1,
      all: 'true',
    };

    return await fetch(`${apiUrls.getPessoas}?${parseOptions(options)}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  insertRenach = async (payload, id) => {
    return await fetch(`${apiUrls.insertRenach}/${id}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  getServicesSales = async (codigoVenda) => {
    return await fetch(`${apiUrls.getVenda}/servicos/${codigoVenda}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //REQUEST PARA PERMISSÃO
  permissaoUser = async () => {
    return await fetch(apiUrls.permissao, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  //REQUESTS PARA PARCELAS
  verMovimentacoes = async (id) => {
    return await fetch(`${apiUrls.verMovimentacao}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  gerarRecibo = async (id, gerarRecibo, tipo = 0, numeroVias = '') => {
    const options = {
      tipo: tipo,
      'numero-vias': numeroVias || null,
    };

    const params = parseOptions(options);
    return await fetch(`${apiUrls.gerarRecibo}/${id}?${params}`, {
      method: 'POST',
      body: JSON.stringify(gerarRecibo),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  gerarCarne = async (id, gerarCarne) => {
    return await fetch(`${apiUrls.gerarCarne}/${id}`, {
      method: 'POST',
      body: JSON.stringify(gerarCarne),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //REQUESTS PARA PROCESSO
  getProcessos = async () => {
    return await fetch(apiUrls.getProcessos, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getServices = async () => {
    return await fetch(apiUrls.getServicesList, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getProcesso = async (id) => {
    return await fetch(`${apiUrls.getProcesso}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  gerarContrato = async (id, gerarContrato) => {
    return await fetch(`${apiUrls.gerarContrato}/${id}`, {
      method: 'POST',
      body: JSON.stringify(gerarContrato),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  gerarFile = async (id, gerarFile) => {
    return await fetch(`${apiUrls.gerarFile}/${id}`, {
      method: 'POST',
      body: JSON.stringify(gerarFile),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  gerarPromissoria = async (id, promissoria) => {
    return await fetch(`${apiUrls.gerarpromissoria}/${id}`, {
      method: 'POST',
      body: JSON.stringify(promissoria),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  gerarCobranca = async (id, formaCobranca) => {
    return await fetch(`${apiUrls.gerarCobranca}/${id}`, {
      method: 'POST',
      headers: makeHeaders(),
      body: JSON.stringify({ formaCobranca: formaCobranca }),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getDadosRenach = async (id) => {
    return await fetch(`${apiUrls.getDadosRenach}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getDadosRenachAlunos = async (id) => {
    return await fetch(`${apiUrls.getDadosRenachAluno}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  // REQUESTS PARA CIDADE
  getCep = async (options) => {
    return await fetch(`${apiUrls.getCep}/${options}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getCitiesAutoFill = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.getCities}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getCountriesAutoFill = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.getCountries}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getCity = async (options) => {
    return await fetch(`${apiUrls.getCities}/${options}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //REQUESTS PARA LEADS
  fetchListLeads = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.getLeads}?${params}&`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  insertLead = async (payload) => {
    return await fetch(apiUrls.insertLead, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  loseLead = async (payload, LeadID) => {
    return await fetch(`${apiUrls.loseLead}/${LeadID}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  fetchLead = async (leadID) => {
    return await fetch(`${apiUrls.getLeads}/${leadID}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchContactsLead = async (leadID) => {
    return await fetch(`${apiUrls.getContatoLead}/${leadID}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  insertContactLead = async (payload) => {
    return await fetch(apiUrls.insertContactLead, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  deleteLead = async (id) => {
    return await fetch(`${apiUrls.deleteLead}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  //REQUEST PARA AS CONFIGS
  getCompany = async () => {
    return await fetch(apiUrls.getCompany, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchPaymentConditions = async () => {
    return await fetch(`${apiUrls.fetchPaymentConditions}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchCredentials = async () => {
    return await fetch(`${apiUrls.fetchCredentials}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  updateCredentials = async (payload) => {
    return await fetch(`${apiUrls.fetchCredentials}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchServicePaymentConditions = async () => {
    return await fetch(`${apiUrls.fetchServicePaymentConditions}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchServiceDetail = async () => {
    return await fetch(`${apiUrls.fetchServiceDetail}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchRelatorioServicosVendidos = async (options) => {
    const params = parseOptions(options);

    return await fetch(`${apiUrls.relatoriosServicosVendidos}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  downloadRelatorioServicosVendidos = async (options) => {
    const params = parseOptions(options);

    return await fetch(`${apiUrls.relatoriosServicosVendidos}/xlsx?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  insertPaymentConditions = async (payload) => {
    return await fetch(`${apiUrls.fetchPaymentConditions}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getBoleto = async (id) => {
    return await fetch(`${apiUrls.boleto}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getNFSe = async (id) => {
    return await fetch(`${apiUrls.getNFSe}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getListCharges = async () => {
    return await fetch(apiUrls.getListCharges, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getListTokens = async () => {
    return await fetch(apiUrls.fetchTokensList, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  insertNewToken = async (payload) => {
    return await fetch(apiUrls.insertNewToken, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  deleteToken = async (id) => {
    return await fetch(`${apiUrls.deleteToken}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  updateCompany = async (payload) => {
    return await fetch(apiUrls.updateCompany, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  updatePageConfig = async (payload) => {
    return await fetch(apiUrls.updatePageConfig, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchPageData = async () => {
    return await fetch(apiUrls.fetchPageData, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getLocation = async () => {
    return await fetch(apiUrls.getLocation, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchListUsers = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.getUsers}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  insertUser = async (payload) => {
    return await fetch(apiUrls.insertUser, {
      method: 'POST',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    }).then(parseResponse);
  };

  updateUser = async (payload) => {
    return await fetch(`${apiUrls.updateUser}/${payload.codigo}`, {
      method: 'PUT',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    }).then(parseResponse);
  };

  getUserData = async (userID) => {
    return await fetch(`${apiUrls.getUserData}/${userID}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  inactiveUser = async (id, status) => {
    return await fetch(`${apiUrls.deleteUser}/${id}/${status}`, {
      method: 'DELETE',
      headers: makeHeaders(),
      body: JSON.stringify({
        cliente: 1,
      }),
    }).then(parseResponse);
  };

  deleteUser = async (id) => {
    return await fetch(`${apiUrls.deleteUser}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
      body: JSON.stringify({
        cliente: 1,
      }),
    }).then(parseResponse);
  };

  updatePassword = async (payload) => {
    return await fetch(apiUrls.updatePassword, {
      method: 'PATCH',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    }).then(parseResponse);
  };

  getEmployeeData = async (employeeID) => {
    return await fetch(`${apiUrls.getEmployee}/${employeeID}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  insertEmployee = async (payload) => {
    return await fetch(apiUrls.insertEmployee, {
      method: 'POST',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    }).then(parseResponse);
  };

  updateEmployee = async (payload, id) => {
    return await fetch(`${apiUrls.updateEmployee}/${id}`, {
      method: 'PUT',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    }).then(parseResponse);
  };

  isDeletableEmployee = async (id) => {
    return await fetch(`${apiUrls.deletePessoa}/${id}/verificar-se-pode-excluir`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  deleteEmployee = async (id) => {
    return await fetch(`${apiUrls.deleteEmployee}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
      body: JSON.stringify({
        funcionario: 1,
      }),
    }).then(parseResponse);
  };

  inactiveEmployee = async (id, status) => {
    return await fetch(`${apiUrls.deleteEmployee}/${id}/${status}`, {
      method: 'DELETE',
      headers: makeHeaders(),
      body: JSON.stringify({
        funcionario: 1,
      }),
    }).then(parseResponse);
  };

  fetchListEmployees = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.getPessoas}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  fetchEmployees = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchEmployees}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  insertProvider = async (payload) => {
    return await fetch(apiUrls.insertProvider, {
      method: 'POST',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    }).then(parseResponse);
  };

  deleteProvider = async (id) => {
    return await fetch(`${apiUrls.deletePessoa}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
      body: JSON.stringify({
        fornecedor: 1,
      }),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchProccess = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchProccess}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  fetchAccounts = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchAccounts}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  fetchExtrato = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchExtrato}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  downloadExtrato = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.downloadExtrato}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  fetchServices = async (id) => {
    return await fetch(`${apiUrls.fetchServices}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  verificarTaxaConfigurada = async (id) => {
    return await fetch(`${apiUrls.verificarTaxaConfigurada}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getProccess = async (id) => {
    return await fetch(`${apiUrls.getProccess}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  insertProccess = async (payload) => {
    return await fetch(apiUrls.insertProccess, {
      method: 'POST',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    }).then(parseResponse);
  };

  updateProccess = async (payload) => {
    return await fetch(`${apiUrls.updateProccess}/${payload.codigo}`, {
      method: 'PUT',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    }).then(parseResponse);
  };

  getTipo = async () => {
    return await fetch(apiUrls.getTipo, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  inactiveProccess = async (id, status) => {
    return await fetch(`${apiUrls.deleteProccess}/${id}/${status}`, {
      method: 'DELETE',
      headers: makeHeaders(),
      body: JSON.stringify({
        cliente: 1,
      }),
    }).then(parseResponse);
  };

  deleteProccess = async (id) => {
    return await fetch(`${apiUrls.deleteProccess}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
      body: JSON.stringify({
        cliente: 1,
      }),
    }).then(parseResponse);
  };

  getVeiculosInstrutores = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.getVeiculosInstrutores}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getVehiclesByInstructor = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.getVehiclesByInstructor}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //Arquivos

  getContract = async (id) => {
    return await fetch(`${apiUrls.getContract}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchListContracts = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchListContracts}?${params}&tipo=0`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  insertContract = async (payload) => {
    return await fetch(apiUrls.insertContract, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  updateContract = async (payload) => {
    return await fetch(`${apiUrls.insertContract}/${payload.codigo}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  deleteContract = async (id) => {
    return await fetch(`${apiUrls.deleteContract}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  getPrint = async (id) => {
    return await fetch(`${apiUrls.getPrint}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchListPrints = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchListPrints}?${params}&tipo=1`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  insertPrint = async (payload) => {
    return await fetch(apiUrls.insertPrint, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  updatePrint = async (payload) => {
    return await fetch(`${apiUrls.insertPrint}/${payload.codigo}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  deletePrint = async (id) => {
    return await fetch(`${apiUrls.deletePrint}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  deleteFile = async (id) => {
    return await fetch(`${apiUrls.deleteFile}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  viewFile = async (id) => {
    return await fetch(`${apiUrls.viewFile}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //FINANCEIRO

  fetchListarFormasCobranca = async () => {
    return await fetch(apiUrls.fetchListarFormasCobranca, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchListStatement = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchListStatement}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  insertExpense = async (payload) => {
    return await fetch(apiUrls.insertExpense, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  deleteExpense = async (id) => {
    return await fetch(`${apiUrls.deleteExpense}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  //VEÍCULOS

  fetchListVehicles = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchListVehicles}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  fetchFiltersVehicles = async () => {
    return await fetch(`${apiUrls.expensesVehiclesFilters}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  fetchVehicle = async (id) => {
    return await fetch(`${apiUrls.fetchVehicle}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  insertVehicle = async (payload) => {
    return await fetch(apiUrls.insertVehicle, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  updateVehicle = async (payload) => {
    return await fetch(`${apiUrls.updateVehicle}/${payload.codigo}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  deleteVehicle = async (id) => {
    return await fetch(`${apiUrls.deleteVehicle}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  inactiveVehicle = async (id, status) => {
    return await fetch(`${apiUrls.deleteVehicle}/${id}/${status}`, {
      method: 'DELETE',
      headers: makeHeaders(),
      body: JSON.stringify({
        tipo: 1,
      }),
    }).then(parseResponse);
  };

  //PERIODOS

  fetchListPeriods = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchListPeriods}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  fetchPeriod = async (id) => {
    return await fetch(`${apiUrls.fetchPeriod}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  insertPeriod = async (payload) => {
    return await fetch(apiUrls.insertPeriod, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  updatePeriod = async (payload) => {
    return await fetch(`${apiUrls.updatePeriod}/${payload.codigo}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  deletePeriod = async (id) => {
    return await fetch(`${apiUrls.deletePeriod}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  fetchInstructor = async () => {
    return await fetch(apiUrls.fetchInstructors, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchVehicles = async () => {
    return await fetch(apiUrls.fetchVehicles, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //Agenda
  insertClass = async (payload) => {
    return await fetch(apiUrls.insertClass, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  updateClass = async (payload) => {
    return await fetch(`${apiUrls.updateClass}/${payload.codigo}`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  confirmClass = async (payload) => {
    return await fetch(`${apiUrls.confirmClass}/${payload.codigo}`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  updateObservation = async (payload) => {
    return await fetch(`${apiUrls.updateObservation}/${payload.codigo}`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  deleteClass = async (payload) => {
    return await fetch(`${apiUrls.deleteClass}/${payload.codigo}`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //Agenda Prática
  fetchAulasPraticasMesmoHorario = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchAulasPraticasMesmoHorario}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchListVehiclesInstructor = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchListVehiclesInstructor}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchInstructorSchedule = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchInstructorSchedule}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchExamSchedule = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchExamSchedule}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  getExamData = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.getExamData}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchStudentSchedule = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchStudentScheduleNew}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchSchedule = async () => {
    return await fetch(apiUrls.fetchSchedule, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  fetchConfigSchedule = async () => {
    return await fetch(apiUrls.fetchConfigSchedule, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  fetchCategoriesStudent = async (id) => {
    return await fetch(`${apiUrls.categoriesSchedule}?pessoa=${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  printSchedule = async (id, gerarAgenda) => {
    return await fetch(`${apiUrls.printSchedule}/${id}`, {
      method: 'POST',
      body: JSON.stringify(gerarAgenda),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  printScheduleHistory = async (id, filtros, gerarAgenda) => {
    const params = parseOptions(filtros);
    return await fetch(`${apiUrls.printScheduleHistory}?${params}`, {
      method: 'POST',
      body: JSON.stringify(gerarAgenda),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  printScheduleInstructor = async (gerarAgenda) => {
    return await fetch(`${apiUrls.printScheduleInstructor}`, {
      method: 'POST',
      body: JSON.stringify(gerarAgenda),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  insertSchedule = async (payload) => {
    return await fetch(apiUrls.insertSchedule, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  updateSchedule = async (payload) => {
    return await fetch(apiUrls.updateSchedule, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  deleteSchedule = async (payload) => {
    return await fetch(`${apiUrls.deleteSchedule}/${payload}`, {
      method: 'DELETE',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  fetchStudentVehicles = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchStudentVehicles}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  buscarHorariosAgendamentoMultiplo = async (payload) => {
    return await fetch(apiUrls.buscarHorariosAgendamentoMultiplo, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  agendarMultiplo = async (payload) => {
    return await fetch(apiUrls.agendamentoMultiplo, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //Dashboard
  fetchConfig = async () => {
    return await fetch(apiUrls.fetchConfig, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  fetchContractDay = async () => {
    return await fetch(apiUrls.contractDay, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  contractCompany = async () => {
    return await fetch(apiUrls.contractCompany, {
      method: 'PATCH',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  gerarFaturaPrePago = async () => {
    return await fetch(apiUrls.gerarFaturaPrePago, {
      method: 'POST',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  insertOnboarding = async (data) => {
    return await fetch(apiUrls.onboarding, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchDashboardData = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchDashboardData}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  DownloadDetail = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.DownloadDetail}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  DownloadStatementDetail = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.DownloadStatementDetail}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  DownloadIncomeDetail = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.DownloadIncomeDetail}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  DownloadExpensesDetail = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.DownloadExpensesDetail}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  DownloadSalesDetail = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.DownloadSalesDetail}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  DownloadStudentsDetail = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.DownloadStudentsDetail}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  DownloadAccountsDetail = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.DownloadAccountsDetail}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //RESTOREID
  setRestoreId = async (payload) => {
    return await fetch(`${apiUrls.setRestoreId}/${payload}`, {
      method: 'PATCH',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //GUIAS
  emitirGuia = async (payload, sigla) => {
    return await fetch(`${apiUrls.imitirGuia}/${sigla}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //NPS
  sendNPS = async (payload) => {
    return await fetch(apiUrls.sendNPS, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getNPS = async () => {
    return await fetch(apiUrls.getNPS, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //SERVIÇOS

  fetchListServices = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchListServices}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  fetchService = async (id) => {
    return await fetch(`${apiUrls.fetchService}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  insertService = async (payload) => {
    return await fetch(apiUrls.insertService, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  updateService = async (payload) => {
    return await fetch(`${apiUrls.updateService}/${payload.codigo}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  deleteService = async (id) => {
    return await fetch(`${apiUrls.deleteService}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  inactiveService = async (id, status) => {
    return await fetch(`${apiUrls.inactiveService}/${id}/${status}`, {
      method: 'DELETE',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  fetchTypeService = async () => {
    return await fetch(apiUrls.fetchTypeService, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchHistoryClasses = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchHistoryClasses}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  desmarcarAulas = async (payload) => {
    return await fetch(`${apiUrls.desmarcarAulas}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getNews = async () => {
    return await fetch(apiUrls.getNews, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  readNews = async () => {
    return await fetch(apiUrls.getNews, {
      method: 'POST',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  updateConfigExam = async (payload) => {
    return await fetch(apiUrls.configExam + '/parametros', {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  updateConfigExamSchedule = async (payload) => {
    return await fetch(apiUrls.configExam + '/horarios', {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchConfigExam = async () => {
    return await fetch(apiUrls.configExam, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  validateExam = async (payload) => {
    return await fetch(apiUrls.validateExam, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  updateExam = async (payload) => {
    return await fetch(apiUrls.updateExam + '/' + payload.codigo, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  printExam = async (id, options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.printExam}/${id}?${params}`, {
      method: 'POST',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  printExamTodos = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.printExam}?${params}`, {
      method: 'POST',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  deleteExam = async (id) => {
    return await fetch(apiUrls.deleteExam + '/' + id, {
      method: 'DELETE',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  alterarStatusExame = async (id, status) => {
    return await fetch(apiUrls.updateExam + '/' + id + '/' + status, {
      method: 'PATCH',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  validateDeleteExam = async (payload) => {
    return await fetch(apiUrls.validateHoursExam + '/' + payload, {
      method: 'DELETE',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getVehicleInstructorStudents = async (payload) => {
    return await fetch(apiUrls.getVehicleInstructorStudents + '/' + payload, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //PROTOCOLOS
  fetchListModelProtocols = async (options) => {
    const params = parseOptions(options);
    return await fetch(apiUrls.fetchListModelProtocols + '?' + params, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchModelProtocol = async (id) => {
    return await fetch(apiUrls.fetchListModelProtocols + '/' + id, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  deleteModelsProtocols = async (payload) => {
    return await fetch(apiUrls.deleteModelsProtocols + '/' + payload, {
      method: 'DELETE',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  insertModelsProtocols = async (payload) => {
    return await fetch(apiUrls.insertModelsProtocols, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  updateModelsProtocols = async (payload) => {
    return await fetch(`${apiUrls.updateModelsProtocols}/${payload.codigo}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchListProtocols = async (options) => {
    const params = parseOptions(options);
    return await fetch(apiUrls.fetchListProcolos + '?' + params, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  updateProtocolData = async (payload) => {
    return await fetch(`${apiUrls.updateProtocolData}/${payload.codigo}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  insertProtocolData = async (payload) => {
    return await fetch(`${apiUrls.updateProtocolData}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchProtocolData = async (id) => {
    return await fetch(`${apiUrls.fetchProtocolData}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  deleteProtocol = async (id) => {
    return await fetch(`${apiUrls.fetchProtocolData}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  gerarProtocolo = async (id, gerarProtocolo) => {
    return await fetch(`${apiUrls.gerarProtocolo}/${id}/imprimir`, {
      method: 'POST',
      body: JSON.stringify(gerarProtocolo),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchAlunosProtocolos = async (options) => {
    const params = parseOptions({
      ...options,
      pageSize: 50,
      situacao: 1,
      cliente: 1,
      search: options.search ? options.search : undefined,
    });

    return await fetch(`${apiUrls.getPessoas}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchAlunos = async (payload) => {
    const options = {
      ...payload,
      situacao: 1,
      cliente: 1,
    };
    const params = parseOptions(options);
    return await fetch(`${apiUrls.getPessoas}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //Integrações
  deleteIntegration = async (id) => {
    return await fetch(`${apiUrls.integration}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchListIntegrations = async (options) => {
    const params = parseOptions(options);
    return await fetch(apiUrls.integration + '?' + params, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchIntegrationData = async (id) => {
    return await fetch(apiUrls.integration + '/' + id, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  updateIntegrationData = async (payload) => {
    return await fetch(`${apiUrls.integration}/${payload.codigo}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  insertIntegrationData = async (payload) => {
    return await fetch(`${apiUrls.integration}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  enviarAulasIntegracoes = async (payload) => {
    return await fetch(`${apiUrls.integrationSchedule}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  updatePasswordIntegration = async (payload) => {
    return await fetch(`${apiUrls.integrationSchedulePassword}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchHistoryIntegration = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.integrationSchedule}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getIntegracaoAulas = async (id) => {
    return await fetch(`${apiUrls.integrationSchedule}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //Permissões

  fetchPermission = async (id) => {
    return await fetch(`${apiUrls.permissions}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchListPermissions = async () => {
    return await fetch(`${apiUrls.fetchPermissions}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  updatePermission = async (payload, id) => {
    return await fetch(`${apiUrls.permissions}/${id}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //Histórico

  fetchHistory = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchHistory}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchFiltersHistory = async (id) => {
    return await fetch(`${apiUrls.filtersHistory}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //Transferir Aulas

  fetchClasses = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchTransfer}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchFiltersTransfer = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.filtersTransfer}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  transferClasses = async (payload) => {
    return await fetch(`${apiUrls.fetchTransfer}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //DESPESA DE VEICULOS
  fetchProdutosServicos = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchProdutosServicos}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  insertProdutosServicos = async (payload) => {
    return await fetch(`${apiUrls.fetchProdutosServicos}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchListDespesasVeiculos = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.expensesVehicles}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  insertDespesaVeiculo = async (payload) => {
    return await fetch(`${apiUrls.expensesVehicles}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  updateDespesaVeiculo = async (payload) => {
    return await fetch(`${apiUrls.expensesVehicles}/${payload.codigo}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getExpenses = async (id) => {
    return await fetch(`${apiUrls.expensesVehicles}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  deleteDespesaVeiculo = async (id) => {
    return await fetch(`${apiUrls.expensesVehicles}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //Recursos
  fetchResources = async (id) => {
    return await fetch(`${apiUrls.fetchResources}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //Registro via Happier
  verifyTokenCompleteRegistration = async (token) => {
    return await fetch(`${apiUrls.signup}/verify/${token}`, {
      method: 'GET',
    }).then(parseResponse);
  };

  completeRegistration = async (payload) => {
    return await fetch(`${apiUrls.signup}`, {
      method: 'POST',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    }).then(parseResponse);
  };

  //Compensação/Taxas
  fetchCompensacao = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.compensacao}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  insertCompensacao = async (payload) => {
    return await fetch(`${apiUrls.compensacao}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  deleteCompensacao = async (id) => {
    return await fetch(`${apiUrls.compensacao}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getCompensacao = async (id) => {
    return await fetch(`${apiUrls.compensacao}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  updateCompensacao = async (payload) => {
    return await fetch(`${apiUrls.compensacao}/${payload.codigo}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchListTaxes = async (id) => {
    return await fetch(`${apiUrls.getTaxes}/${id}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchCobrancas = async () => {
    return await fetch(`${apiUrls.fetchCobrancas}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchObservationFinancial = async () => {
    return await fetch(`${apiUrls.fetchObservation}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  updateObservationFinancial = async (payload) => {
    return await fetch(`${apiUrls.fetchObservation}`, {
      method: 'PUT',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  buscarTotalizadorAulas = async (aluno, categoria) => {
    const params = parseOptions(categoria);
    return await fetch(
      `${apiUrls.totalizadorAulas}/${aluno}/totalizadores?${params}`,
      {
        method: 'GET',
        headers: makeHeaders(),
      }
    )
      .then(parseResponse)
      .catch(parseErrors);
  };

  buscarInstrutoresEVeiculos = async (options) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.instrutoresEveiculos}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  buscarCategorias = async (alunoID) => {
    return await fetch(`${apiUrls.getPessoa}/${alunoID}/categorias-ativas`, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };
}

export default new Api();
